/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateClientById } from 'services/clients/apiClient.services';
import {
  ClientFormState,
  CropArrayOptionsType,
  IAdditionalInformationEdit,
  ICreateClientsFormData,
  ICropTypes,
  IFormType,
  InforArray,
  INumberOfLevel,
  IOptionalProperty,
  IOptionalPropertyType,
  ITabPanelType,
  SelectionOptionType,
} from '../../common/defines/clients';
import { defaultClientFormData } from '../../common/dummy/dummyClients';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearClientUpdateState, clientSelector } from '../../store/slices/clientSlice';
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';
import { ClientActionsDialog } from './ClientActionsDialog';
import ClientDataUploadAvailability from './ClientDataUploadAvailability';
import { ClientFormActions } from './ClientFormActions';
import { ClientMenu } from './ClientMenu';
import { ClientsAdditionalModal } from './ClientsAdditionalModal';
import { CropTypeTab } from './LevelPropertiesTab';

const customSelectionOptions: SelectionOptionType[] = [];

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps, tabType: ITabPanelType) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ flex: 1 }}
      {...other}>
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            maxWidth: tabType === ITabPanelType.DEFAULT ? '260px' : 'none',
            flex: tabType === ITabPanelType.LEVEL ? '1' : 'none',
          }}>
          <Typography sx={{ width: '100%' }} component="div">
            {children}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

let cropArrayOptions: CropArrayOptionsType[] = [];
let numberOfLevel: INumberOfLevel[] = [];

const initialAnchorEl = {
  level: null,
  property: null,
  removeLevel: null,
};

export const ClientLevelProperties = ({ nextStep, setIsUpdateData, formType }: ClientFormState) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const navigate = useNavigate();

  const { clientData } = useAppSelector(clientSelector);
  const dataIDRedux = useSelector((state: any) => state.clientDataUser._id);

  const [open, setOpen] = useState(false);
  const [showMoreIconProperty, setShowMoreIconProperty] = useState<null | number>(null);
  const [showMenuIconLevel, setShowMenuIconLevel] = useState<null | number>(null);
  const [selectedCropType, setSelectedCropType] = useState<ICropTypes>();
  const [tabIndex, setTabIndex] = useState(0);
  const [levelCount, setLevelCount] = useState<number>(0);
  const [levelNumberArray, setLevelNumberArray] = useState<number[]>([]);
  const [modalLevel, setModalLevel] = useState<number | undefined>();
  const [checkDuplicateError, setCheckDuplicateError] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [propertyName, setProperTyName] = useState('');
  const [currentLevel, setCurrentLevel] = useState(0);
  const [isDeleteLevel, setIsDeleteLevel] = useState(false);
  const [indexLevel, setIndexLevel] = useState<null | number>(null);
  const [editData, setEditData] = useState<IAdditionalInformationEdit | null>(null);

  const [anchorEl, setAnchorEl] = useState<{
    level: null | HTMLElement;
    property: null | HTMLElement;
    removeLevel: null | HTMLElement;
  }>(initialAnchorEl);

  const openMenu =
    (anchorName: string, propertyName: string, currentLevel: number, isDeleteLevel?: boolean) =>
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl({
        ...anchorEl,
        [anchorName]: event.currentTarget,
      });
      setProperTyName(propertyName);
      setCurrentLevel(currentLevel);
      if (isDeleteLevel) setIsDeleteLevel(isDeleteLevel);
      else setIsDeleteLevel(false);
    };
  const closeMenu = (anchorName: string) => () => {
    setAnchorEl({
      ...anchorEl,
      [anchorName]: null,
    });
    setProperTyName('');
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setAnchorEl(initialAnchorEl);
  };

  const handleChange = (newValue: number) => {
    setSelectedCropType(cropArrayOptions[newValue].value);
    setLevelNumberArray(cropArrayOptions[newValue].level);
    setLevelCount(cropArrayOptions[newValue].level.length);
    setTabIndex(newValue);
  };

  const handleOpenModal = (num: number) => {
    setModalLevel(num);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setModalLevel(undefined);
    setEditData(null);
    setOpen(false);
  };

  const mapCustomSelectOptions = (selectOptions: string) => {
    let options = selectOptions.split(', ');
    options = Array.from(new Set(options));
    options.map((dt: string) => {
      customSelectionOptions.push({ value: dt, key: dt });
      return customSelectionOptions;
    });
  };

  const form = useForm<ICreateClientsFormData>({
    defaultValues: {
      ...(clientData || defaultClientFormData),
    },
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    setIsUpdateData && setIsUpdateData(isDirty);
  }, [isDirty, setIsUpdateData]);

  const levelValueCheck = (data: ICreateClientsFormData) => {
    data.optionalProperties?.forEach((infor, index, arr) => {
      if (!Array.isArray(infor.value)) {
        if (infor.type === IOptionalPropertyType.SELECT) {
          const levelOption = (arr[index].value as string).split(/,(?! )/, -1);
          arr[index].value = levelOption;
          return;
        }
        return (arr[index].value = [infor.value] as unknown as string);
      }
    });
    return data;
  };

  const handleSubmitCropTypeId = (data: ICreateClientsFormData) => {
    const cropTypeIdArray: any[] = [];
    data.cropType?.forEach((cropType: any) => {
      cropTypeIdArray.push(cropType?._id || '');
    });
    return (data = {
      ...data,
      cropType: cropTypeIdArray,
    });
  };

  const handleUpdateClient = useMutation((data: ICreateClientsFormData) => updateClientById(data.id || '', data), {
    onSuccess: () => {
      if (!nextStep) return;
      if (formType === IFormType.ADD) {
        return navigate(`/clients/edit/${dataIDRedux}`);
      }
      nextStep(3);
    },
  });

  const handleSubmitForm = handleSubmit((data) => {
    levelValueCheck(data);
    const fixedData = handleSubmitCropTypeId(data);
    const propertiesArray: IOptionalProperty[] = [];
    fixedData.cropType.forEach((value: any) => {
      fixedData.optionalProperties
        ?.filter((val) => val.cropType === value)
        .forEach((type) => propertiesArray.push(type));
    });

    handleUpdateClient.mutate({
      ...fixedData,
      id: clientData?._id,
      avatar: fixedData?.avatar,
      numberOfLevel,
      optionalProperties: propertiesArray,
      additionalInformation: fixedData.additionalInformation,
    });
  });

  const handleOnBack = () => {
    if (!nextStep) return;
    nextStep(0);
    if (formType === IFormType.ADD) {
      return navigate(`/clients/edit/${dataIDRedux}`);
    }
  };

  const { fields, append, remove, replace, update } = useFieldArray({
    name: 'optionalProperties',
    control,
  });

  const handleGetCropTypeText = (id: string) => {
    const allCropTypeData = queryClient.getQueryData(QUERY_KEY.USER_SETTINGS_CROPTYPE);
    if (!allCropTypeData) return;
    const selectedCropType = (allCropTypeData as any)?.data.find((val: any) => val._id === id);
    return selectedCropType.name;
  };

  const handleGetCropTypeIcon = (id: string) => {
    const allCropTypeData = queryClient.getQueryData(QUERY_KEY.USER_SETTINGS_CROPTYPE);
    if (!allCropTypeData) return;
    const selectedCropType = (allCropTypeData as any)?.data.find((val: any) => val._id === id);
    return selectedCropType.icon;
  };

  useEffect(() => {
    dispatch(clearClientUpdateState());
    cropArrayOptions = [];
    numberOfLevel = [];
    function levelForEachCropType(dt: number) {
      const levelForCropType: number[] = [];
      for (let i = 1; i <= dt; i += 1) {
        levelForCropType.push(i);
      }
      return levelForCropType;
    }

    function makeCropTypeArray() {
      clientData?.numberOfLevel?.forEach((value, index) => {
        numberOfLevel.push({
          type: value.type,
          value: value.value,
        });
        cropArrayOptions.push({
          value: value.type,
          text: handleGetCropTypeText(value.type),
          level: levelForEachCropType(value.value),
          icon: handleGetCropTypeIcon(value.type),
        });
      });
      return cropArrayOptions;
    }

    makeCropTypeArray();
    setSelectedCropType(cropArrayOptions.length > 0 ? cropArrayOptions[0].value : undefined);
    setTabIndex(0);
    setLevelNumberArray(cropArrayOptions.length ? cropArrayOptions[0].level : []);
    setLevelCount(cropArrayOptions.length ? cropArrayOptions[0].level.length : 0);

    if (getValues('optionalProperties')?.length === 0) {
      clientData?.cropType?.forEach((type: any) => {
        append({
          name: 'Name',
          type: IOptionalPropertyType.TEXT,
          value: [],
          level: 0,
          isDefault: true,
          cropType: type._id,
        });
        // append({
        //   name: 'Date Planted',
        //   type: IOptionalPropertyType.DATE,
        //   value: [],
        //   level: 0,
        //   isDefault: true,
        //   cropType: type._id,
        // });
      });
      return;
    }
    if (clientData && clientData.optionalProperties?.length !== 0) {
      const checkCropType: any = [];
      cropArrayOptions.forEach((crop) => {
        const newField = fields.filter((props) => props.cropType === crop.value);
        checkCropType.push(newField);
      });

      cropArrayOptions.forEach((crop, index) => {
        if (checkCropType[index].length !== 0) return;
        // append({
        //   name: 'Name',
        //   type: IOptionalPropertyType.TEXT,
        //   value: [],
        //   level: 0,
        //   isDefault: true,
        //   cropType: crop.value,
        // });
        // append({
        //   name: 'Date Planted',
        //   type: IOptionalPropertyType.DATE,
        //   value: [],
        //   level: 0,
        //   isDefault: true,
        //   cropType: crop.value,
        // });
      });
    }
  }, [append, clientData, dispatch]);

  numberOfLevel = [...new Map(numberOfLevel.map((item) => [item.type, item])).values()];

  const handleAddLevelButton = (idx: number) => {
    if (levelCount >= 5 || levelNumberArray.length === 5 || cropArrayOptions[idx].level.length === 5) return;
    setLevelCount(levelCount + 1);
    setLevelNumberArray([...levelNumberArray, levelCount + 1]);
    cropArrayOptions[idx].level.push(levelCount + 1);
    numberOfLevel[idx].value = levelCount + 1;
  };

  const handleFormatSelectOptions = (data: string) => {
    let options = data.split(/,(?! )/, -1);
    options = Array.from(new Set(options));
    return options;
  };

  const additionalField = (data: InforArray) => {
    if (data.id) {
      const index = fields.findIndex((res) => res.id === data.id);
      update(index, {
        name: data.name,
        type: data.propertyType,
        level: data.level,
        isDefault: data.level === 0,
        cropType: data.cropType,
        value: '',
        listValue: handleFormatSelectOptions(data?.inputLength || data?.selectOptions),
      });
      return;
    }
    if (
      fields
        .filter((val) => val.cropType === data.cropType)
        .filter((value) => value.level === data.level && value.name === data.name).length > 0 ||
      fields
        .filter((val) => val.cropType === data.cropType)
        .filter((value) => value.level === 0 && value.name === data.name).length > 0
    ) {
      setCheckDuplicateError(true);
      return;
    }
    switch (data.propertyType) {
      case IOptionalPropertyType.DATE:
        append({
          name: data.name,
          type: IOptionalPropertyType.DATE,
          value: [],
          level: data.level,
          isDefault: data.level === 0,
          cropType: data.cropType,
        });
        break;
      case IOptionalPropertyType.FILE:
        append({
          name: data.name,
          type: IOptionalPropertyType.FILE,
          value: [],
          level: data.level,
          isDefault: data.level === 0,
          cropType: data.cropType,
        });
        break;
      case IOptionalPropertyType.SELECT:
        append({
          name: data.name,
          type: IOptionalPropertyType.SELECT,
          value: [],
          level: data.level,
          cropType: data.cropType,
          listValue: handleFormatSelectOptions(data?.selectOptions),
          isDefault: data.level === 0,
        });
        mapCustomSelectOptions(data?.selectOptions);
        break;
      case IOptionalPropertyType.TEXT:
        append({
          name: data.name,
          type: IOptionalPropertyType.TEXT,
          value: data?.inputLength,
          level: data.level,
          cropType: data.cropType,
          isDefault: data.level === 0,
        });
        break;
      default:
        break;
    }
  };

  const onSave = () => {
    if (isDeleteLevel) {
      const mutable = levelNumberArray.filter((i) => i < currentLevel);
      cropArrayOptions[tabIndex] = {
        ...cropArrayOptions[tabIndex],
        level: mutable,
      };
      numberOfLevel[tabIndex] = {
        ...numberOfLevel[tabIndex],
        value: mutable.length,
      };
      setLevelNumberArray(mutable);
      setLevelCount(mutable.length);
      const newFields = fields.filter((i) => {
        if (i.level && i.level > 0 && i.cropType === selectedCropType && i.level >= currentLevel) {
          return false;
        } else {
          return true;
        }
      });
      replace(newFields);
    } else {
      const fieldIndex = fields.findIndex(
        (field) => field.cropType === selectedCropType && field.name === propertyName && field.level === currentLevel
      );
      if (fieldIndex !== -1) {
        remove(fieldIndex);
      }
    }
    closeDeleteModal();
  };

  return (
    <Box>
      {!clientData && <LoadingOverlay />}
      <form onSubmit={handleSubmitForm}>
        <Stack direction="row">
          <Scrollbars style={{ height: 'calc(100vh - 220px)', minWidth: '280px', width: '280px' }} autoHide>
            <Box>
              <Typography
                sx={{
                  pt: 2.5,
                  fontFamily: 'Barlow',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '21px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                }}>
                Level Properties
              </Typography>
              <Typography
                sx={{
                  pt: 2.5,
                  fontFamily: 'Barlow',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                }}>
                Type
              </Typography>
              <Box sx={{ maxWidth: '299px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  {cropArrayOptions.map((type, index) => {
                    return (
                      <Grid item xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                        <Tooltip title={type.text} placement="top" arrow>
                          <Button
                            sx={{
                              mt: 2.5,
                              width: '120px',
                              height: '40px',
                              textTransform: 'none',
                              fontWeight: '400',
                              color: tabIndex === index ? theme.palette.primary.main : '#616161',
                              border:
                                tabIndex === index
                                  ? `1px solid ${theme.palette.primary.main}`
                                  : `1px solid ${theme.palette.divider}`,
                              borderRadius: '5px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '10px',
                            }}
                            key={type.value}
                            onClick={() => handleChange(index)}>
                            <img
                              style={{
                                width: '15px',
                                height: '15px',
                                color: theme.palette.mode === 'dark' ? '#fff' : '#616161',
                                borderRadius: '50%',
                                filter:
                                  tabIndex === index
                                    ? 'invert(56%) sepia(16%) saturate(1896%) hue-rotate(95deg) brightness(105%) contrast(88%)'
                                    : 'invert(24%) sepia(7%) saturate(390%) hue-rotate(163deg) brightness(97%) contrast(96%)',
                              }}
                              src={type.icon}
                              alt="icon"
                            />
                            <Typography
                              sx={{
                                fontSize: '.875rem',
                                fontWeight: '400',
                                lineHeight: '1.4375em',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginLeft: '8px',
                              }}>
                              {type.text}
                            </Typography>
                          </Button>
                        </Tooltip>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
              {cropArrayOptions.map((type, index) => {
                return (
                  <TabPanel value={tabIndex} index={index} key={type.value}>
                    <Box
                      sx={{
                        maxWidth: '260px',
                        width: '260px',
                        mt: 3.75,
                        backgroundColor: '#009F400D',
                        borderRadius: '5px',
                      }}>
                      <Typography
                        sx={{
                          pt: 2.5,
                          pl: 2.5,
                          fontFamily: 'Barlow',
                          fontWeight: '600',
                          fontSize: '16px',
                          lineHeight: '21px',
                          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                        }}>
                        Default Properties
                      </Typography>
                      <Typography
                        sx={{
                          pt: 2.5,
                          px: 2.5,
                          fontFamily: 'Barlow',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '18px',
                          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                          textAlign: 'left',
                        }}>
                        You have{' '}
                        <Typography
                          component="span"
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                            fontFamily: 'Barlow',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '17px',
                          }}>
                          {fields.filter((res) => res.isDefault && res.cropType === type.value).length}
                        </Typography>{' '}
                        properties set to default for all levels
                      </Typography>
                      <Button
                        sx={{
                          width: '220px',
                          maxWidth: '220px',
                          height: '40px',
                          mx: '20px',
                          mt: '20px',
                          mb: '10px',
                          textTransform: 'none',
                          fontFamily: 'Barlow',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '17px',
                          // backgroundColor: (theme) => theme.palette.primary.main,
                          // color: (theme) => (theme.palette.mode === 'dark' ? '#4C4043' : '#616161'),
                          backgroundColor: '#009F401A',
                          color: theme.palette.primary.main,
                          // border: `1px solid ${theme.palette.divider}`,
                          border: 'none',
                          borderRadius: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          transition: 'all 0.3s ease-in-out',
                          '.MuiButton-endIcon': {
                            m: 0,
                          },
                          '&:hover': {
                            // backgroundColor: (theme) => theme.palette.primary.main,
                            backgroundColor: '#009F401A',
                            color: theme.palette.primary.main,
                            opacity: '0.8',
                            // color: '#FFFFFF',
                          },
                        }}
                        onClick={() => handleOpenModal(0)}>
                        <Stack sx={{ px: 1.5 }}>Add New Property</Stack>
                        <Add />
                      </Button>
                      <Box sx={{ pb: '10px' }}>
                        {fields
                          .filter((res) => res.level === 0 && res.cropType === type.value)
                          .map((property, idx) => {
                            return (
                              <Controller
                                name={`optionalProperties.${idx}.name`}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`optionalProperties.${idx}.name`}
                                control={control}
                                render={({ field }) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '220px',
                                      maxWidth: '220px',
                                      height: '40px',
                                      mx: '20px',
                                      my: '10px',
                                      borderRadius: '5px',
                                    }}
                                    onMouseLeave={() => setShowMoreIconProperty(null)}
                                    onMouseEnter={() => setShowMoreIconProperty(idx)}>
                                    <Typography
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        pl: '10px',
                                        textTransform: 'none',
                                        fontFamily: 'Barlow',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        color: '#C7C7C7',
                                        border: `1px solid ${theme.palette.divider}`,
                                        backgroundColor: (theme) =>
                                          theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                          backgroundColor: '#1976d20a',
                                          color: theme.palette.primary.main,
                                        },
                                      }}
                                      {...field}>
                                      {property.name}
                                    </Typography>
                                    {showMoreIconProperty === idx && idx > 1 && (
                                      <ClientMenu
                                        anchorEl={anchorEl.property}
                                        open={Boolean(anchorEl.property)}
                                        onOpen={openMenu('property', property.name, 0)}
                                        onClose={closeMenu('property')}
                                        handleEdit={() => {
                                          setEditData({
                                            id: property.id,
                                            name: property.name,
                                            type: property.type,
                                            value: '',
                                          });
                                          handleOpenModal(0);
                                        }}
                                        onDelete={() => setOpenDeleteModal(true)}
                                        mainMenu={false}
                                      />
                                    )}
                                  </Box>
                                )}
                              />
                            );
                          })}
                      </Box>
                    </Box>

                    <ClientDataUploadAvailability selectedCropType={selectedCropType} form={form} />
                  </TabPanel>
                );
              })}
            </Box>
          </Scrollbars>
          <Box sx={{ border: '0.5px solid #EDF1F1', mr: '40px' }} />
          <Scrollbars style={{ height: 'calc(100vh - 220px)' }} autoHide>
            <Box sx={{ ml: '40px', display: 'flex', flex: '1', pr: '30px' }}>
              <Stack sx={{ width: '100%' }}>
                {cropArrayOptions.map((type, index) => {
                  return (
                    <CropTypeTab
                      tabIndex={tabIndex}
                      control={control}
                      index={index}
                      type={type}
                      levelNumberArray={levelNumberArray}
                      indexLevel={indexLevel}
                      setIndexLevel={setIndexLevel}
                      anchorEl={anchorEl}
                      openMenu={openMenu}
                      closeMenu={closeMenu}
                      setOpenDeleteModal={setOpenDeleteModal}
                      handleOpenModal={handleOpenModal}
                      fields={fields}
                      setShowMenuIconLevel={setShowMenuIconLevel}
                      showMenuIconLevel={showMenuIconLevel}
                      setEditData={setEditData}
                      handleAddLevelButton={handleAddLevelButton}
                      levelCount={levelCount}
                      key={index}
                    />
                  );
                })}
                <ClientFormActions onBack={() => handleOnBack()} isClear={false} />
              </Stack>
            </Box>
          </Scrollbars>
        </Stack>
      </form>

      {/* duplicate */}
      <ClientActionsDialog
        title="Error"
        open={checkDuplicateError}
        onClose={() => setCheckDuplicateError(false)}
        onSave={() => setCheckDuplicateError(false)}
        submit={false}
      />

      {/* delete */}
      <ClientActionsDialog
        title={propertyName ? 'Delete property' : 'Delete level'}
        open={openDeleteModal}
        onClose={closeDeleteModal}
        description="Are you sure about that?"
        onCancel={closeDeleteModal}
        onSave={onSave}
        submit={false}
      />

      <ClientsAdditionalModal
        open={open}
        handleClose={handleCloseModal}
        additionalField={additionalField}
        cropType={selectedCropType}
        level={modalLevel}
        dataAdditional={editData}
      />
    </Box>
  );
};
