import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, InputLabel, styled, useTheme } from '@mui/material';
import { HomeLocation } from 'components/MapView/SideBar/HomeLocation';
import ScrollbarsCustom from 'components/ScrollbarsCustom';
import { QUERY_KEY } from 'constants/constants';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { get, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from 'react-query';
import { getRasterListByPagination } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  anaLyticConfigSelector,
  changePageRasterPagination,
  changeRasterPagination,
  changeSensorSelected,
  resetRasterPagination,
} from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { DialogCreateRaster } from './Dialog/DialogCreateRaster';
import { InfoRasterCard } from './InfoRasterCard';
import { RasterList } from './Raster/RasterList';
import { useAnalyticsConfigStyle } from './style';

const ButtonAddRaster = styled(Button)(({ theme }) => ({
  border: `1px dashed ${theme.palette.color.grey2}`,
  borderRadius: theme.spacing(0.5),
  width: '100%',
  height: theme.spacing(6),
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  cursor: 'pointer',
  color: 'initial',
  marginBottom: '10px',
  maxHeight: '37px',
}));

const GridInfo = styled(Grid)(({ theme }) => ({
  height: '100%',
}));

export const AnalyticsConfig = () => {
  const dispatch = useAppDispatch();
  const classes = useAnalyticsConfigStyle();
  const { listRasterPagination, pageRaster } = useAppSelector(anaLyticConfigSelector);
  const { levelId } = useAppSelector(mapViewSelector);
  const [isShowCropType, setIsShowCropType] = useState<boolean>(true);
  const [isShowRaster, setIsShowRaster] = useState<boolean>(true);
  const [isOpenDialogCreate, setIsOpenDialogCreate] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchClient, setSearchClient] = useState(null);
  const [analysisArea, setAnalysisArea] = useState(null);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(0);
  const theme = useTheme();
  const { t } = useTranslation();

  const listRasterData = useMemo(() => {
    if (!listRasterPagination) {
      return [];
    }
    return listRasterPagination;
  }, [listRasterPagination]);

  const { data: dataConfiguration, isLoading } = useQuery(
    [QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId],
    () => getRasterListByPagination(pageRaster, rowsPerPage, searchClient, analysisArea, levelId || null),
    {
      cacheTime: 1,
      enabled: !isEmpty(levelId),
      onSuccess: (data: any) => {
        const Array = data?.data.docs || [];
        const newArray = pageRaster === 1 ? Array : [...listRasterData, ...Array];
        dispatch(changeRasterPagination(newArray));
        const total = get(data, 'data.metadata[0].total', 0);
        const limit = get(data, 'data.metadata[0].limit', 0);
        setHasMore(newArray.length < total);
        setTotal(total);
        setLimit(limit);
      },
    }
  );

  useEffect(() => {
    dispatch(changePageRasterPagination(1));
    dispatch(resetRasterPagination());
  }, [dispatch, levelId]);

  const fetchMoreData = () => {
    pageRaster * limit < total && dispatch(changePageRasterPagination(pageRaster + 1));
  };

  const { isRTL } = useCheckRTL();

  return (
    <>
      <Grid className={classes.dataConfigurationCard}>
        <Grid container spacing={2} sx={{ height: '100%' }} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}>
          <GridInfo item sx={{ minWidth: '300px', display: isShowCropType ? 'block' : 'none' }}>
            <InputLabel sx={{ fontWeight: '600', pt: 2 }}>{t('trans.type')}</InputLabel>
            <ScrollbarsCustom style={{ height: 'calc( 100% - 60px)' }} autoHide>
              <HomeLocation />
            </ScrollbarsCustom>
          </GridInfo>
          {levelId && (
            <>
              <GridInfo
                item
                sx={{
                  borderRight: `1px solid ${theme.palette.divider}`,
                  borderLeft: isShowCropType ? `1px solid ${theme.palette.divider}` : 'none',
                  position: 'relative',
                  padding: '16px 20px',
                  minWidth: '450px',
                  display: isShowRaster ? '' : 'none',
                  height: '100%',
                }}>
                <Button
                  sx={{
                    position: 'absolute',
                    [isRTL ? 'right' : 'left']: '-12px',
                    top: '28px !important',
                    transform: isRTL ? 'rotate(180deg)' : 'none',
                  }}
                  className={classes.navBarButton}
                  onClick={() => setIsShowCropType((value) => !value)}>
                  {isShowCropType ? (
                    <ArrowBackIosNewIcon className={classes.arrowIcon} fontSize="small" />
                  ) : (
                    <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />
                  )}
                </Button>
                <InputLabel sx={{ fontWeight: '600', pt: 2 }}>{t('trans.raster')}</InputLabel>
                <ButtonAddRaster
                  onClick={() => {
                    dispatch(changeSensorSelected({ sensor: null, type: null }));
                    setIsOpenDialogCreate(true);
                  }}>
                  <AddIcon
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                    }}
                  />
                </ButtonAddRaster>
                {listRasterData?.length > 0 && (
                  <InfiniteScroll
                    dataLength={listRasterData?.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    height="87%"
                    loader={<h3 style={{ fontSize: '14px' }}>Loading...</h3>}
                    className={'scroll-bar-custom'}>
                    <RasterList sensorList={listRasterData} />
                  </InfiniteScroll>
                )}
              </GridInfo>
              <Grid
                item
                sx={{
                  height: '100%',
                  position: 'relative',
                  minWidth: '750px',
                  maxWidth: `calc(100% - 750px)`,
                  padding: 0,
                }}>
                <Button
                  sx={{
                    position: 'absolute',
                    [isRTL ? 'right' : 'left']: '-12px',
                    top: '28px !important',
                    transform: isRTL ? 'rotate(180deg)' : 'none',
                  }}
                  className={classes.navBarButton}
                  onClick={() => setIsShowRaster((value) => !value)}>
                  {isShowRaster ? (
                    <ArrowBackIosNewIcon className={classes.arrowIcon} fontSize="small" />
                  ) : (
                    <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />
                  )}
                </Button>
                <ScrollbarsCustom autoHide autoHeightMin="100%">
                  <InfoRasterCard />
                </ScrollbarsCustom>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {isOpenDialogCreate && levelId && (
        <DialogCreateRaster levelId={levelId} onClose={() => setIsOpenDialogCreate(false)} />
      )}
    </>
  );
};
