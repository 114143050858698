import DoneIcon from '@mui/icons-material/Done';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { ICreateClientsFormData } from 'common/defines/clients';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import WarningDialog from 'components/Dashboard/AnalyticsConfig/Form/WarningDialog';
import { FC, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';

interface ClientDataUploadAvailabilityProps {
  selectedCropType: string | undefined;
  form: UseFormReturn<ICreateClientsFormData, any>;
}

const ClientDataUploadAvailability: FC<ClientDataUploadAvailabilityProps> = ({ selectedCropType, form }) => {
  const [isOpenWarningModal, setIsOpenWarningModal] = useState<boolean>(false);
  const [isEnableUploadAllLevels, setIsEnableUploadAllLevels] = useState<boolean>(false);

  const { getValues, setValue } = form;
  const { clientData } = useAppSelector(clientSelector);
  const { name: clientName, cropType: cropTypeData } = clientData || {};
  const uploadAnalysisConfig = useMemo(() => {
    return getValues('uploadAnalysisConfig') || [];
  }, [getValues]);

  const cropTypeName = cropTypeData?.find((item) => item._id === selectedCropType)?.name || '';

  useEffect(() => {
    const foundAnalytic = uploadAnalysisConfig.find((item) => item.cropType === selectedCropType);
    if (foundAnalytic) {
      setIsEnableUploadAllLevels(foundAnalytic.isUpload);
    }
  }, [selectedCropType, uploadAnalysisConfig]);

  const maxLevel = useMemo(() => {
    if (!clientData || !selectedCropType) {
      return 0;
    }

    return clientData.numberOfLevel?.find((item: any) => item.type === selectedCropType)?.value || 0;
  }, [clientData, selectedCropType]);

  const handleClickChangingUploadAllLevels = () => {
    setIsOpenWarningModal(true);
  };

  const closeWarningModal = () => {
    setIsOpenWarningModal(false);
  };

  const onSubmitWarningModal = () => {
    const foundedCropTypeId = uploadAnalysisConfig.find((item) => item.cropType === selectedCropType);
    if (!selectedCropType) return;

    setIsEnableUploadAllLevels(true);
    setIsOpenWarningModal(false);

    if (foundedCropTypeId) {
      const newUploadAnalysisConfig = uploadAnalysisConfig.map((item) =>
        item.cropType === selectedCropType ? item : { ...item, isUpload: true }
      );
      setValue('uploadAnalysisConfig', newUploadAnalysisConfig);
    } else {
      const newUploadAnalysisConfig = [...uploadAnalysisConfig, { cropType: selectedCropType, isUpload: true }];
      setValue('uploadAnalysisConfig', newUploadAnalysisConfig);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '260px',
        width: '260px',
        mt: 3.75,
        backgroundColor: '#009F400D',
        borderRadius: '5px',
        py: 2.5,
        pl: 2.5,
      }}>
      <Typography
        sx={{
          fontFamily: 'Barlow',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '21px',
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
        }}>
        Data Upload availability
      </Typography>
      <Typography
        sx={{
          pt: 1.5,
          fontFamily: 'Barlow',
          fontSize: '14px',
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
        }}>
        {isEnableUploadAllLevels ? 'Upload at all Levels' : `Upload analytic at Level ${maxLevel}`}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {isEnableUploadAllLevels ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DoneIcon color="primary" />
            <Typography component={'span'}>Enable data upload all level</Typography>
          </Box>
        ) : (
          <FormControlLabel
            sx={{ pl: 1.25 }}
            control={
              <SwitchCustom checked={isEnableUploadAllLevels} onClick={() => handleClickChangingUploadAllLevels()} />
            }
            label={'Enable data upload all level'}
          />
        )}
      </Box>
      <WarningDialog
        title="Warning"
        open={isOpenWarningModal}
        onClose={closeWarningModal}
        onCancel={closeWarningModal}
        onSubmit={onSubmitWarningModal}
        data={`This action is irreversible, confirm to enable analytic in parent-level for ${clientName} in ${cropTypeName} properties?`}
        cancelLabel="No"
        submitLabel="Yes"
      />
    </Box>
  );
};

export default ClientDataUploadAvailability;
